<template>
    <div></div>
</template>
<!--template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="$store.state.verticalMenu.notifications.length"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Cảnh báo camera nhiệt
          </h4>
          
        </div>
      </li>

      <div style="max-height:400px;overflow:auto">
        <b-link
            v-for="(notification,i) in $store.state.verticalMenu.notifications"
            :key="i"
          >
            <b-media class="p-1 border-bottom">
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ notification.DeviceID }} - {{ notification.CameraId }} - {{ notification.AreaName }}
                </span>
              </p>
              <small class="notification-text">Nhiệt độ cao {{ notification.MaxTemperature }}, thấp {{ notification.MinTemperature }}</small>
            </b-media>
          </b-link>
      </div>

      <li class="dropdown-menu-footer"><b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        :to="{ name: 'alarm-list' }"
        block
      >Xem tất cả cảnh báo</b-button>
      </li>
    </b-nav-item-dropdown>
    <b-modal body-class="p-0" size="lg" id="modal-alarm" title="Cảnh báo" hide-footer>
      <div class="p-1">
        <h3>Camera 175B1F Vị trí đo DCL 112, cảnh báo nhiệt độ cao 40 độ</h3>
        <img class="d-block img-fluid w-100" :src="require('@/assets/images/svg/20220219000058_C.jpg')">
      </div>
    </b-modal>
  </div>
</template-->

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    SockJS,Stomp,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //notifications: []
    }
  },
  methods: {
    connect() {
      this.socket = new SockJS("http://localhost:9010/websocket");
      this.stompClient = Stomp.over(this.socket);
      this.stompClient.connect({},
        frame => {
          this.connected = true;
          this.stompClient.subscribe("/alarm/web", tick => {
            var x = JSON.parse(tick.body);
            this.$bvModal.show('modal-alarm')
            this.$store.state.verticalMenu.notifications.push(x);
            console.log(this.$store.state.verticalMenu.notifications);
          });
        },
        error => {
          console.log(error);
          this.connected = false;
        }
      );
    },
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      this.connected = false;
    },
  },
  mounted () {
    this.connect();
  },
  destroyed: function(){
    this.disconnect();
  },
  setup() {
    /* eslint-disable global-require */
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
